@font-face {
  font-family: "Raleway";
  src: url("./Raleway.ttf");
}

body {
  font-family: "Raleway", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: 0.5s;
  overflow-x: hidden;
  line-height: 1.8em;
  font-family: "Raleway", sans-serif;
}

h1,
p {
  margin: 0;
  margin-bottom: 1em;
}

a {
  text-decoration: none;
}

code {
  padding: 1em 4em;
  line-height: 1.2em;
  border: 1em solid rgb(85, 85, 255);
  background-color: rgb(125, 125, 255);
  border-left: 10em solid rgb(85, 85, 255);
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Works on Firefox */
* {
  scrollbar-width: 0;
  /* scrollbar-color: #ffd65b #c6c6c6; */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  /* width: 8px; */
  width: 0;
}

*::-webkit-scrollbar-track {
  background: #c6c6c6;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffd65b;
  border-radius: 20px;
  /* border: 3px solid #C6C6C6; */
}
